
//import img1 from "../../../assets/imagen/pasado1.png"
//import img2 from "../../../assets/imagen/pasado2.png"
//import img3 from "../../../assets/imagen/pasado3.png"

import header from "../../../assets/header.jpeg";

import logofla from "../../../assets/imagen/LOGO-WEB.png";
//import mapa from "../../../assets/imagen/MAPAPRUEBA.png"
//import portal from "../../../assets/imagen/Portadashows.jpg"
import icon from "../../../assets/imagen/50pixeles.png";
import iconhead from "../../../assets/imagen/LOGO-WEB.png"
//import valla from "../../../assets/imagen/valla-proximo-evento.jpeg";
import android from "../../../assets/imagen/footericon/app1.png";
import ios from "../../../assets/imagen/footericon/app2.png";
import face from "../../../assets/imagen/footericon/face.png";
import insta from "../../../assets/imagen/footericon/instagram.png";
import tick from "../../../assets/imagen/footericon/tictok.png";
import youtube from "../../../assets/imagen/footericon/youtube.png";
import nombrepage from "../../../assets/imagen/nombrepage.png"
import redacti from "../../../assets/redactiva.png"
import facilito from "../../../assets/facilito.png"
import facilitodos from "../../../assets/facilito.jpeg"
import avatar from "../../../assets/imagen/avatarpng.png"
//import prubas from "../../../assets/imagen/quito.jpeg"
//import prubasdos from "../../../assets/imagen/guayaquil.jpg"
//import proximo from "../../../assets/imagen/carrion.jpeg"
//import uno from "../../../assets/imagen/uno.png"
//import dos from ""
//import guayaquil from "../../../assets/imagen/preventa.jpg"
import cedericon from "../../../assets/imagen/iconoceder.png"
let datos = []

export const pasados = []
export const carrusel = {
      header,  logofla,
      icon, 
    android, ios, face, insta, 
    tick, youtube, nombrepage, facilito,
  redacti, facilitodos, avatar, iconhead,   cedericon, 
} 